// import { JackpotGroup } from ";
import FilterFactory from "../../../constants/converters/filterFactory";
import { Player } from "../../../constants/converters/player";
import { actions } from "../common";
import qs from "qs";

const baseUrl = "/api/v1/casinos";
export default {
	...actions(baseUrl, Player),
	async next({ dispatch, getters }) {
		if (!getters.query.next) {
			await dispatch("load", {
				url: getters.query.last,
				failBehavior: false,
			});
		} else if (getters.query.next) {
			await dispatch("load", {
				url: getters.query.next,
				failBehavior: true,
			});
		}
	},
	async previous({ dispatch, getters }) {
		console.log("previous");
		console.log(getters.query.previous);

		if (!getters.query.previous) {
			await dispatch("load", {
				url: getters.query.first,
				failBehavior: false,
			});
		} else if (getters.query.previous) {
			await dispatch("load", {
				url: getters.query.previous,
				failBehavior: true,
			});
		}
	},
	async first({ dispatch, getters }, sessionId) {
		await dispatch("load", { url: getters.query.first });
	},
	async last({ dispatch, getters }, sessionId) {
		await dispatch("load", { url: getters.query.last });
	},
	async loadList({ dispatch, getters }, sessionId) {
		const filter = { ...getters.filter, session_mongo_id: sessionId };
		const pagination = getters.pagination;

		await dispatch("load", {
			url: FilterFactory.from(getters.getQsConfig, {
				...filter,
				...pagination,
			}).build(),
			sessionId,
		});
	},
	async load(
		{ dispatch, commit, rootGetters, getters },
		{ url, failBehavior = false }
	) {
		try {
			console.log("loading");

			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: `/api/v2/casinos/${rootGetters.currentCasino}/players?${url}`,
					protectedPath: true,
				},
				{ root: true }
			);
			if (response.data.data && response.data.data.length === 0) {
				return;
			}
			if (
				response.data.data &&
				response.data.data.length <
					qs.parse(url, { ignoreQueryPrefix: true }).limit &&
				failBehavior
			) {
				dispatch("first");
				return;
			}

			let data = response.data.data.map((el) =>
				Player.fromDTO(el, rootGetters.currentCasino)
			);

			commit("list", data);
			commit("query", response.data.pagination);
			return response.data.pagination;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			if (e.response.status !== 400) {
				console.error(`Error ocurred while fetching list from ${baseUrl}`, e);
				dispatch(
					"notification/setMessage",
					{ message: "Failed to load sessions list", type: "error" },
					{ root: true }
				);
			}
			commit("list", []);
			commit("pagination", {
				total_pages: 0,
				total_records: 0,
				page: 1,
			});
		}
	},
	async loadDetail(
		{ dispatch, commit, rootGetters },
		{ player_id, casino_id = rootGetters.currentCasino }
	) {
		const url = `${baseUrl}/${casino_id}/players/${player_id}`;
		try {
			const response = await dispatch(
				"apiCall/loganGet",
				{
					path: url,
					protectedPath: true,
				},
				{ root: true }
			);
			let data = Player.fromDTO(response.data);
			commit("detail", data);
			return true;
		} catch (e) {
			if (e.message === "canceled") {
				throw e;
			}
			console.error(`Error ocurred while fetching detail from ${url}`, e);
			dispatch(
				"notification/setMessage",
				{ message: "Player does not exist.", type: "error" },
				{ root: true }
			);
			commit("detail", {});
			return false;
		}
	},
};
